<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <!-- <b-col cols="5"> -->

    <b-button
      class="align-items-center mb-2 createWalletBtn py-2 "
      variant="success"
      :to="`/${$i18n.locale}/customer-wallets/CreateCryptoWallet`"
    >
      <span>
        <feather-icon
          icon="PlusIcon"
          size="15"
        />
        {{ $t('createNewWallet') }}
      </span>
    </b-button>
    <!-- <b-card v-if="cryptoWallets.length <= 0">
      <h4 class="font-weight-bold mb-1">
        {{ $t('guide') }}:
      </h4>
      <p>{{ $t('wallet-desc') }}</p>
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <p class="text-white crypto-card">

            {{ $t('tip') }}: {{ $t('cryptoWallet-desc') }}
          </p>
        </b-col>

      </b-row>

    </b-card> -->
    <!-- <b-card v-if="cryptoWallets.length > 0">
      <h4 class="font-weight-bold mb-1">
        {{ $t('guide') }}:
      </h4>
      <p>{{ $t('no-wallet-desc') }}</p>
      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <p class="text-white crypto-card">

            {{ $t('tip') }}: {{ $t('cryptoWallet-desc') }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert
            show
            variant="danger"
            class="p-1"
          >
            {{ $t('onlyDepositTetherAndDArik') }}
          </b-alert>
        </b-col>
      </b-row>
    </b-card> -->
    <!-- </b-col> -->
    <!-- <b-row
      v-if="$store.state.auth.user.citizenship && $store.state.auth.user.citizenship.abbreviation.toLowerCase() != 'ir'"
    >
      <b-card style="width: 100%;">
        <b-alert
          show
          variant="danger"
          class="p-1"
        >
          {{ $t('onlyIranCitizensCanDeposit') }}
        </b-alert>
      </b-card>
    </b-row> -->
    <b-row>
      <!-- v-if="cryptoWallets.length > 0 && $store.state.auth.user.citizenship && $store.state.auth.user.citizenship.abbreviation.toLowerCase() == 'ir'" -->
      <b-col
        v-for="(item, index) in cryptoWallets"
        :key="index"
        cols="12"
        md="6"
        xl="4"
      >
        <b-card
          v-if="item.nick_name != 'BSC-USD'"
          class="rounded-lg shadow"
          style="overflow:hidden"
        >
          <div class="bubble" />
          <b-row align-h="between">
            <b-col class="d-flex align-center">
              <b-avatar :src="getCoinAvatar(item.nick_name)" />
              <div class="coins mx-2 d-flex flex-column align-center my-auto">
                <h6>{{ (item.coin_name ? item.coin_name : item.name) + (item.coin_name != 'Tether' ? (' / ' +
                  item.network_name) : '') }}</h6>
                <!-- <span>{{ item.network_name }}</span> -->
              </div>
            </b-col>

          </b-row>
          <hr style="z-index:-1">

          <div
            :class="$store.state.appConfig.layout.skin=='dark'?'text-white':''"
            class="h3 m-1"
          >
            <span>{{ item.quantity }}</span>
            <span class="mx-1">{{ item.nick_name }}</span>
          </div>
          <!-- <b-row
            align-h="between"
            align-v="center"
            class="mb-2 mx-1"
          >
            <b-col class="text-secondary">
              <span>$</span>
              <span>1.1231234</span>
            </b-col>
            <b-col cols="auto">
              <b-badge
                variant="danger"
                style="border-radius:2rem !important"
              >
                -2.1%
              </b-badge>
            </b-col>
          </b-row> -->
          <hr>
          <b-row
            class="d-flex mt-2"
            align-h="center"
          >
            <b-col cols="5">
              <b-button
                class="d-flex justify-content-center align-items-center m-auto customHover"
                variant="success"
                :to="`/${$i18n.locale}/customer-wallets/crypto-deposit/${(item.coin_name ? item.coin_name : item.name).toLowerCase()}/${item.coinPair.toLowerCase()}/${item.network_name.toLowerCase()}`"
              >
                <span>{{ $t('crypto_wallet_deposit') }}</span>
              </b-button>
            </b-col>
            <b-col cols="5">
              <b-button
                class="d-flex justify-content-center align-items-center m-auto customHover"
                variant="danger"
                :to="`/${$i18n.locale}/customer-wallets/crypto-withdraw/${(item.coin_name ? item.coin_name : item.name).toLowerCase()}/${item.coinPair.toLowerCase()}/${item.network_name.toLowerCase()}`"
              >
                <span> {{ $t('crypto_wallet_withdrawal') }} </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import orderHistorySocket from '@/services/websockets/orderHistorySocket'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BAvatar,
    BOverlay,
  },

  data() {
    return {
      backgroundImg: '',
      backgroundColor: 'black',
      show: true,
      cryptoWallets: [],
    }
  },
  computed: {
    isUserExist() {
      return this.$store.state.auth.user?.id
    },

  },
  watch: {
    isUserExist(val) {
      if (val) {
        this.connectAndGetwallets()
      }
    },

  },
  created() {
    if (this.isUserExist) {
      this.connectAndGetwallets()
    }
  },
  beforeDestroy() {
    orderHistorySocket.removeMessageEvent('getWallets')
    orderHistorySocket.removeMessageEvent('wallets')
    orderHistorySocket.removeMessageEvent('getAccount')
    orderHistorySocket.removeMessageEvent('account')
  },
  methods: {
    connectAndGetwallets() {
      orderHistorySocket.setVueStore(this.$store)
      orderHistorySocket.connect()
      orderHistorySocket.send('reqWallets', {
        status: [0],
        limit: 10,
        page: 1,
      })
      orderHistorySocket.send('reqAccount', {
        status: [0],
        limit: 10,
        page: 1,
      })
      orderHistorySocket.setMessageEvent(
        'getWallets',
        'wallets',
        async data => {
          // if (data.docs[0].cabin_id === 658898) {
          //   await data.docs.forEach(item => {
          //     // eslint-disable-next-line no-param-reassign
          //     item.quantity *= 3
          //   })
          // }

          this.cryptoWallets = [...this.cryptoWallets, ...data.docs.filter(item => (item.nick_name !== 'BSC-USD'))]
          this.show = false
        },
      )
      orderHistorySocket.setMessageEvent(
        'getAccount',
        'account',
        async data => {
          // if (data.cabin_id === 658898) {
          //   const newBalance = await data.balance_usdt
          //   // eslint-disable-next-line no-param-reassign
          //   data.balance_usdt = newBalance * 3
          // }

          this.cryptoWallets = [{
            coinPair: 'USDT', nick_name: 'USDT', coin_name: 'Tether', coin_id: 0, quantity: data.balance_usdt, network_name: 'TRC20',
          }, ...this.cryptoWallets]

          // this.show = false
        },
      )
      orderHistorySocket.setMessageEvent(
        'account',
        'accountChange',
        async data => {
          switch (data.type) {
            case 'UPDATE':
              // eslint-disable-next-line array-callback-return
              this.cryptoWallets.map(item => {
                // eslint-disable-next-line eqeqeq
                if (item.coinPair == 'USDT' && item.network_name == 'TRC20') {
                  // if (this.isUserExist === 658898) {
                  // eslint-disable-next-line no-param-reassign
                  // item.quantity = data.change.balance_usdt * 3
                  // } else {
                  // eslint-disable-next-line no-param-reassign
                  item.quantity = data.change.balance_usdt
                  // }
                }
              })
              break
            default:
              break
          }
          // this.show = false
        },
      )
      orderHistorySocket.setMessageEvent(
        'wallets',
        'walletChange',
        async data => {
          if (data.error) {
            console.log(data.error)
          } else {
            const newQuantity = await data.change.quantity
            // if (this.isUserExist === 658898) {
            //   // eslint-disable-next-line operator-assignment, no-param-reassign
            //   data.change.quantity = newQuantity * 3
            // }
            switch (data.type) {
              case 'INSERT':
                // if (0 == data.change.status) {
                this.cryptoWallets.push(data.change)
                // }
                this.$emit('walletChange', this.cryptoWallets)
                break
              case 'UPDATE':
                // eslint-disable-next-line no-case-declarations
                let itemIndex = false
                this.cryptoWallets = this.cryptoWallets.map((item, index) => {
                  // eslint-disable-next-line eqeqeq
                  if (item.wallet_id == data.change.wallet_id) {
                    itemIndex = index
                  }
                  return item
                })
                if (itemIndex === false) {
                  this.cryptoWallets.push(data.change)
                } else {
                  // eslint-disable-next-line array-callback-return
                  Object.keys(data.change).map(key => {
                    this.cryptoWallets[itemIndex][key] = data.change[key]
                  })
                }
                this.$emit('walletChange', this.cryptoWallets)
                break
              case 'DELETE':
                // find order in array and delete it
                // eslint-disable-next-line eqeqeq
                this.cryptoWallets = this.cryptoWallets.filter(item => item.wallet_id != data.change.wallet_id)
                this.$emit('walletChange', this.cryptoWallets)
                break
              default:
                break
            }
          }
        },
      )
    },

    getCoinAvatar(coin) {
      let img = ''

      try {
        if (coin.toLowerCase() === 'darik') {
          // eslint-disable-next-line import/no-dynamic-require, global-require
          img = require(`@/assets/images/currencies/${coin.toLowerCase()}.png`)
          return img
        }
        if (coin.toLowerCase() === 'bsc-usd') {
          // eslint-disable-next-line import/no-dynamic-require, global-require
          img = require('@/assets/images/currencies/usdt.svg')
          return img
        }
        // eslint-disable-next-line import/no-dynamic-require, global-require
        img = require(`@/assets/images/currencies/${coin.toLowerCase()}.svg`)
        return img
      } catch (e) {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        img = require('@/assets/images/currencies/default.svg')
        return img
      }
    },
  },

}
</script>
<style scoped>
.custom-btn {
  font-size: 12px;
}

.createWallet {
  width: 4.3rem;
  transition: all .3s ease;
  display: flex;
  align-items: center;
}

.createWallet:hover {
  width: 100%;
}

.createSpan:nth-child(1) {
  transition: all .7s cubic-bezier(.23, -0.04, .77, -1.49)
}

.createSpan:nth-child(2) {
  visibility: hidden;
  transition: all .3s;
  white-space: nowrap;
}

.createWallet:hover .createSpan:nth-child(2) {
  visibility: visible;
}

.createWallet:hover .createSpan:nth-child(1) {
  transform: rotate(90deg);
}

/* .bubble{
  position: absolute;
  top:-1rem;
  right:-1rem;
  width: 14rem;
  height:14rem;
  border-radius: 50%;
  background: #fafbffdc !important;
} */

.customHover {
  transition: all .2s;
  backface-visibility: hidden;
}

/* .customHover:hover{
  transform: scale(1.1) translateZ(0);
} */

.createWalletBtn {
  width: 250px;
}

@media only screen and (max-width: 600px) {
  .createWalletBtn {
    width: 100%;
  }
}

.crypto-card {
  background-color: #ffb065;
  padding: 11px;
  border-radius: 4px;
  opacity: 90%;
}
</style>
