import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class FiatApis {
    getFiatInfo = async data => await axiosIns.get('auth/wallet', data);

    getFiatHistory = async data => await axiosIns.get(`auth/wallet/history${data ? (`?${Object.keys(data).map(key => `${key}=${data[key]}`).join('&')}`) : ''}`);

    getCurrency = async data => await axiosIns.get(`auth/wallet/currency/price${objectToUrlParam(data)}`);

    saveWithdrawal = async data => await axiosIns.post('auth/withdrawal-ticket-bank-module/store', data)

    bankAccountInfo = async () => await axiosIns.get('auth/deposit-ticket-bank-module/banks', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    saveDeposit = async data => {
      const formData = new FormData()
      Object.keys(data).forEach(element => formData.append(element, data[element]))
      return await axiosIns.post('auth/deposit-ticket-bank-module/store', formData)
    }
}
