<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >

    <b-row>
      <b-col
        v-for="(cards,index) in result"
        :key="index+'fiatCards'"
        class="col-xxl-3 col-sm-12 col-md-6"
      >
        <FiatCard
          :cards="cards"
          :background-img="backgroundImg"
          :background-color="backgroundColor"
          :card-number="cardNumber"
          :deposit-link="'customer-wallets/fiat/deposit/'+cards.currency"
          :widraw-link="'customer-wallets/fiat/withdraw/'+cards.currency"
          :history-link="'customer-wallets/fiat/history/'+cards.currency"
        />
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>

import {
  BCol, BRow, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FiatCard from '@/views/components/wallet/FiatCard.vue'
import FiatApis from '@/services/apis/fiat'

const userFiat = new FiatApis()
export default {
  components: {
    BCol,
    BRow,
    FiatCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      backgroundImg: '',
      backgroundColor: '#7367f0',
      cardNumber: '#### #### #### ####',
      result: [],
      show: true,

    }
  },
  mounted() {
    userFiat.getFiatInfo().then(res => {
      this.result = res.data.results
      this.show = false
    }).catch(error => {
      console.log(error)
      this.show = false
    })
  },

}
</script>
