<template>
  <b-tabs
    v-model="tabIndex"
    content-class="col-12 col-md-12 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
    class="row"
  >
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold general">{{ $t('crypto_currencies_wallets') }}</span>
      </template>
      <CryptoWallets />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold general">{{ $t('fiat') }}</span>
      </template>
      <index />
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import CryptoWallets from '@/views/components/wallet/CryptoWallets.vue'
import index from '@/views/customer-wallets/fiat/index.vue'
import tabsMacher from '@/mixins/tabsMacher'

export default {
  components: {
    index,
    BTabs,
    BTab,
    CryptoWallets,
  },
  mixins: [tabsMacher],
}
</script>
<style lang="scss">

</style>
